import React, { useState, useEffect } from 'react';
import App from './App';
import Loader from './Components/Loader';
import { timerPromise } from './helpers';

export default function Wrapper () {
    const [isLoading, setIsLoading] = useState(true);
    const [numOfAssetsLeft, setNumOfAssetsLeft] = useState(6);

    useEffect(() => {
        if (numOfAssetsLeft <= 0) {
            timerPromise(1200).then(() => {
                setIsLoading(false);
            })
        }
    }, [numOfAssetsLeft]);

    const onLoadHandler = () => {
        setNumOfAssetsLeft(prev => prev - 1);
    }

    return (
        <div className="wrapper">
            <Loader isLoading={isLoading}>LOADING</Loader>
            <App onLoadHandler={onLoadHandler} />
        </div>
    )
}
