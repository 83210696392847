import React, { useState } from "react";
import { ItemTypes } from "../App";
import { DragDropContainer } from "react-drag-drop-container";

import { getRandomNum, failTexts, successTexts } from "../helpers";

import Apelsinskal from "../assets/Sopor/Appelsin.png";
import Hörlurar from "../assets/Sopor/Elektronik.png";
import Rivjärn from "../assets/Sopor/Grovsopor.png";
import Konservburk from "../assets/Sopor/Metall.png";
import plast from "../assets/Sopor/Plast.png";
import Tidningar from "../assets/Sopor/Tidningar.png";
import Pocketbok from "../assets/Sopor/Pocketbok.png";
import Skor from "../assets/Sopor/Skor.png";
import Chipspåse from "../assets/Sopor/Chips.png";
import kaffefilter from "../assets/Sopor/Tepåse-kaffefilter.png";

import wrongSound from "../assets/audio/wrong.mp3";
import correctSound from "../assets/audio/correct.mp3";
import winSound from "../assets/audio/win.mp3";

export default function Items({ setIsCorrect, setText, setGameOver, currentItemIdx, setCurrentItemIdx, itemRef, setShowLink }) {
    const items = [
        { id: 'Apelsinskal', type: ItemTypes.FOOD, src: Apelsinskal },
        { id: 'Hörlurar', type: ItemTypes.ELECTRONIC, src: Hörlurar },
        { id: 'Rivjärn', type: ItemTypes.SOLID, src: Rivjärn },
        { id: 'Konservburk', type: ItemTypes.METAL, src: Konservburk },
        { id: 'Tandkräm/schampo', type: ItemTypes.PLASTIC, src: plast },
        { id: 'Pocketbok', type: ItemTypes.PAPER, src: Pocketbok },
        { id: 'Skor', type: ItemTypes.SOLID, src: Skor },
        { id: 'Chipspåse', type: ItemTypes.PLASTIC, src: Chipspåse },
        { id: 'Tepåse/kaffefilter', type: ItemTypes.FOOD, src: kaffefilter },
        { id: 'Tidningar', type: ItemTypes.PAPER, src: Tidningar },
    ];

    const [isDragStart, setIsDragStart] = useState(false);

    const playWrongSound = new Audio(wrongSound);
    const playCorrectSound = new Audio(correctSound);
    const playWinSound = new Audio(winSound);

    const handleDrop = (dragData, currentTarget) => {
        setIsDragStart(false)
        if (currentTarget && currentTarget.id.includes("Bin")) {
            itemRef.current.style.display = "none";
            if (!currentTarget.id.includes(dragData)) {
                setIsCorrect(false);
                playWrongSound.play();
                const randomFailText = failTexts[getRandomNum(0, 2)];
                setText(randomFailText);
            } else {
                if (currentItemIdx >= items.length - 1) {
                    playWinSound.play();
                    setText(`Grattis! Du har sorterat allting rätt.\nNu är du en hejare på att sortera.`);
                    setGameOver(true);
                    setTimeout(() => {
                        setShowLink(true);
                    }, 2500);
                    return;
                }
                setIsCorrect(true);
                playCorrectSound.play();
                const randomSuccessText = successTexts[getRandomNum(0, 2)];
                setText(randomSuccessText);
                setCurrentItemIdx(prev => prev + 1);
            }
            setTimeout(() => {
                itemRef.current.style.display = "block";
                setIsCorrect(null);
                setText("");
            }, 1500);
        }
    }

    return (
        <div className="items-container">
            {
                <div ref={itemRef}>
                    <DragDropContainer
                        dragElemOpacity={1}
                        dragData={items[currentItemIdx].type}
                        onDragEnd={handleDrop}
                        onDragStart={() => setIsDragStart(true)}
                        targetKey={items[currentItemIdx].type}
                    >
                        <img src={items[currentItemIdx].src} alt={items[currentItemIdx].id} />
                        {isDragStart && <p className="tooltip">{items[currentItemIdx].id}</p>}
                    </DragDropContainer>
                </div>
            }
        </div>
    )
}

