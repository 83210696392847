import React from "react";
import yellowStar from "../assets/Icons/Star_Yellow.svg";
import pinkStar from "../assets/Icons/Star_Pink.svg";

const YellowStar = () => (<img className="star yellow" alt="yellow-star" src={yellowStar} width={25} height={25} />);
const PinkStar = () => (<img className="star pink" alt="pink-star" src={pinkStar} width={25} height={25} />);

export default function WinText({ text }) {
    return (
        <div className="win-text-container">
            <div className="star-container left-star">
                <YellowStar />
                <PinkStar />
            </div>
            <p>{text}</p>
            <div className="star-container right-star">
                <PinkStar />
                <YellowStar />
            </div>
        </div>
    )
}
