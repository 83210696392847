import React from "react";
import { ThreeDots } from  "react-loader-spinner";
import loaderIcon from "../assets/Logo/logo.png";

export default function ({ isLoading }) {
    return (
        <div className={isLoading ? "loader" : "hidden"}>
            <img className="loader-logo" src={loaderIcon} />
            <ThreeDots
                height="100"
                width="100"
                radius="9"
                color="white"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
            />
        </div>
    )
}
