import React from 'react'
import { ItemTypes } from "../App";
import { DropTarget } from "react-drag-drop-container";

import Bin_1 from "../assets/Bins/Bin_1.png";
import Bin_2 from "../assets/Bins/Bin_2.png";
import Bin_3 from "../assets/Bins/Bin_3.png";
import Bin_4 from "../assets/Bins/Bin_4.png";
import Bin_5 from "../assets/Bins/Bin_5.png";
import Bin_6 from "../assets/Bins/Bin_6.png";
import Image from "./Image";

export default function Bins({onLoadHandler}) {
    const dustbinContainers = [
        { name: "Bin_1", accept: ItemTypes.SOLID, src: Bin_1 },
        { name: "Bin_2", accept: ItemTypes.FOOD, src: Bin_2 },
        { name: "Bin_3", accept: ItemTypes.METAL, src: Bin_3 },
        { name: "Bin_4", accept: ItemTypes.PLASTIC, src: Bin_4 },
        { name: "Bin_5", accept: ItemTypes.ELECTRONIC, src: Bin_5 },
        { name: "Bin_6", accept: ItemTypes.PAPER, src: Bin_6 },
    ]

    return (
        <div className="dustbin-container">
            {dustbinContainers.map((dustbinItem) => {
                return (
                    <DropTarget key={dustbinItem.name} dropData={dustbinItem.accept} targetKey={dustbinItem.accept} >
                        <Image id={`Bin ${dustbinItem.accept}`} src={dustbinItem.src} alt={dustbinItem.name} width={"100%"} height={"100%"} draggable={false} onLoad={onLoadHandler} />
                    </DropTarget>
                )
            })}
        </div>
    )
}
