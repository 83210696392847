export const failTexts = [
    "Tyvärr! Försök igen.",
    "Ajaj - fel tunna.",
    "Nu blev det fel - försök igen."
]

export const successTexts = [
    "Helt rätt, bra jobbat!",
    "Toppen, du är en riktig återvinnare!",
    "Du är en stjärna på det här!"
]

export const getRandomNum = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

export const timerPromise = (timer) => {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve();
        }, timer);
    })
}