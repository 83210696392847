import React from "react";

import rightIcon from "../assets/Icons/Right_Symbol.svg";
import wrongIcon from "../assets/Icons/Wrong_Symbol.svg";

const RightMark = () => (<img src={rightIcon} alt="icon" width={30} height={30} />);
const WrongMark = () => (<img src={wrongIcon} alt="icon" width={25} height={25} />);

export default function Text({ isCorrect, text }) {
    return (
        isCorrect === null ? null : (
            <div className="textContainer">
                {isCorrect === false ? <WrongMark /> : <RightMark />}
                <p>{text}</p>
            </div>)
    )
}
