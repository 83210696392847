import './App.css';
import "./fonts/FuturaEF-Medium.otf";

import React, { useState, useRef } from 'react';
import Bins from './Components/Bins';
import Items from './Components/Items';

import logo from "./assets/Logo/logo.png"
import buttonSrc from "./assets/Icons/Button.png"
import WinText from './Components/WinText';
import Text from './Components/Text';

export const ItemTypes = {
  FOOD: "food",
  PAPER: "paper",
  SOLID: "solid",
  PLASTIC: "plastic",
  METAL: "metal",
  ELECTRONIC: "electronic"
}

export default function App({ onLoadHandler }) {
  const [gameOver, setGameOver] = useState(false);
  const [text, setText] = useState("");
  const [isCorrect, setIsCorrect] = useState(null);
  const [currentItemIdx, setCurrentItemIdx] = useState(0);
  const [showLink, setShowLink] = useState(false);

  const itemRef = useRef();

  const reset = () => {
    setGameOver(false);
    setText("");
    setIsCorrect(null);
    setCurrentItemIdx(0);
    setShowLink(false);
    itemRef.current.style.display = "block";
  }

  return (
    <div className="App">
      <div className='heading'>
        <img className="logo" src={logo} alt="logo" draggable={false} />
        {gameOver ? null : (
          <div className="title-container">
            <p className="primary-title">SORTERA DINA SOPOR RÄTT.</p>
            <p className="secondary-title">Dra soporna till rätt soptunna.</p>
          </div>
        )}
        <input className="reset" type="image" src={buttonSrc} onClick={reset} alt="reset" />
      </div>

      <Items
        setIsCorrect={setIsCorrect}
        setText={setText}
        setGameOver={setGameOver}
        currentItemIdx={currentItemIdx}
        setCurrentItemIdx={setCurrentItemIdx}
        itemRef={itemRef}
        setShowLink={setShowLink}
      />

      {showLink
        ? <p
          className='link'>Är du osäker på hur olika saker ska sorteras?
          <a
            href='https://familjebostader.com/hyresgast/hyresgastinfo-a-o/sopor-och-atervinning/sopsortera-ratt/'> 
            Lär dig mer här.
          </a>
        </p>
        : gameOver
          ? <WinText text={text} />
          : <Text isCorrect={isCorrect} text={text} />}

      <Bins onLoadHandler={onLoadHandler} />
    </div>
  );
}
